import transparentTextAreaStyles from '~/styles/components/Utility/components/Shimmer.css';

import type { LinksFunction } from '@remix-run/node';
import type { CSSProperties } from 'react';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: transparentTextAreaStyles }];

type Props = {
  className?: string;
  height?: string;
  styles?: CSSProperties;
  variant?: 'image' | 'default';
  width?: string;
};

const Shimmer = ({ className, width = '100%', height = '100%', variant = 'default', styles = {} }: Props) => {
  return <div className={`shimmerSelector -${variant} ${className || ''}`} style={{ width, height, ...styles }} />;
};

export default Shimmer;